<template>
  <div id="home">
        <navbar homepage="false"></navbar>
            <div class="newsletter-container">
                <div class="newsletter-left-column">
                    <h3>Archive</h3>
                    <ul>
                        <li>
                            2018
                            <ul>
                                <li><a class="archive-date" href="01-jan-2018">January</a></li>
                                <li><a class="archive-date" href="01-feb-2018">February</a></li>
                                <li><a class="archive-date" href="01-mar-2018">March</a></li>
                                <li><a class="archive-date" href="01-apr-2018">April</a></li>
                                <li><a class="archive-date" href="01-may-2018">May</a></li>
                                <li><a class="archive-date" href="01-jun-2018">June</a></li>
                                <li><a class="archive-date" href="01-jul-2018">July</a></li>
                                <li><a class="archive-date active" href="01-aug-2018">August</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="newsletter-right-column">
                    <div class="newsletter-right-column-content">
                        <h2>MUSKLE Monthly Newsletter #008</h2>
                        <p class="newsletter-subtitle">
                            "Macros”
                        </p>
                        <p class="newsletter-date">
                            (August 2018)
                        </p>
                        <p class="newsletter-paragraph">
                            Hey guys and gals, welcome to another iteration of Muskle’s information newsletter. This month, we’ll get to know about the types of food we’ll find in a supermarket, and how much we should be consuming. Let’s get started!
                        </p>
                        <p class="newsletter-paragraph">
                            Macros… What are they? All foods contain macros or ‘macronutrients’. The common three which you’ll see all our Instagram models and budding bodybuilders refer to are:
                            <ul class="styled-list">
                                <li>
                                    Proteins
                                </li>
                                <li>
                                    Carbohydrates
                                </li>
                                <li>
                                    Fats
                                </li>
                            </ul>
                        </p>
                        <p class="newsletter-paragraph">
                            A well-balanced diet should see each of the above being consumed in correct portions, and to understand the specific ratios for an individual, we’ll need to speak with a professional dietician or nutritionist.
                        </p>
                        <p class="newsletter-paragraph">
                            Not only do different macros have different reactions in the body, they also have different values when calorie counting.
                        </p>
                        <p class="newsletter-paragraph">
                            Carbs and Proteins contain 4 calories per gram, whereas Fats contain 9 calories per gram. This must be taken into consideration when calculating ratios and amounts of macros consumed.
                        </p>
                        <p class="newsletter-paragraph">
                            Next time you’re supermarket shopping, take a look at the Nutritional Information label on the back of any packaged foods and you’ll see a ratio of Protein, Carbs and Fats, and the amount (in grams) of each.
                        </p>
                        <p class="newsletter-paragraph">
                            Someone’s probably reading this and thinking “But Jamie, what about the 4th macro?”. Well kudos to you, pal. The 4th macro which is often missed is alcohol. Containing 7 cals/g.
                        </p>
                        <p class="newsletter-paragraph">
                            On the topic of alcohol, next month we’ll investigate hormones and how everyday life affects our hormone balance, which ultimately translates into our training and the results we see.
                        </p>
                        <p class="newsletter-preview">
                            Next month sneak peak - hormones
                        </p>
                    </div>
                </div>
            </div>
      </div>
</template>

<script>
import Navbar from '../components/NavBar.vue'

export default {
  name: 'app',
  components: {
    Navbar
  }
}
</script>

<style>
body {
  padding: 0;
  margin: 0;
}
#home {
  margin: 0;
  padding: 0;
}
.styled-list {
                list-style-type: circle;
                padding-top: 0;
                margin-top: 0;
                font-family: 'Roboto', sans-serif;
                font-weight: 300;
            }
  h2 {
      font-family: 'Roboto';
      margin-bottom: 25px;
      text-align: center;
  }
  p {
      font-family: 'Roboto', sans-serif;
  }
  ul {
      list-style-type: none;
  }
  li {
      padding: 5px 0;
      font-family: 'Roboto', sans-serif;
      font-weight: 300;
  }
  .archive-date {
      color: #000;
      text-decoration: none;
  }
  .archive-date:hover {
      text-decoration: underline;
  }
  .active {
      font-weight: 900;
  }
  .newsletter-container {
      margin-top: 125px;
      display: flex;
  }
  .newsletter-left-column {
      flex-grow: 1;
      padding: 10px;
      margin-top: 20px;
      margin-left: 20px;
      max-width: 160px;
  }
  .newsletter-right-column {
      display: flex;
      flex-grow: 5;
      justify-content: center;
      margin-right: 80px;
  }
  .newsletter-right-column-content {
      margin: 50px 100px;
      border: 3px double #ddd;
      padding: 0px 30px;
      max-width: 595px;
  }
  .newsletter-subtitle {
      font-size: 20px;
      font-weight: 300;
  }
  .newsletter-date {
      font-size: 14px;
      font-weight: 500;
  }
  .newsletter-paragraph {
      font-size: 16px;
      font-weight: 300;
      line-height: 28px;
  }
  .newsletter-preview {
      font-size: 16px;
      font-weight: 300;
      text-transform: uppercase;
      text-align: right;
      padding-top: 40px;
  }
  .italic {
      font-style: italic;
  }

  @media only screen and (max-width: 750px) {
      h2 {
          font-size: 18px;
      }
      .newsletter-container {
          flex-direction: column;
      }
      .newsletter-right-column {
          margin-right: 0;
      }
      .newsletter-right-column-content {
          margin: 10px 10px;
          padding: 0 10px;
      }
      .newsletter-subtitle {
          font-size: 16px;
      }
      .newsletter-date {
          font-size: 13px;
      }
      .newsletter-paragraph {
          font-size: 14px;
      }
      .newsletter-preview {
          font-size: 13px;
      }
  }
</style>
